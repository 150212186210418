<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0">
      <div class="card-title">
        <h3 class="card-label">
          <span class="d-block text-dark font-weight-bolder">Satın Alan</span>
        </h3>
      </div>
      <div v-if="requestReview" class="card-toolbar">
        <button
          class="btn btn-light-warning btn-sm font-weight-bolder font-size-sm"
          @click="$bvModal.show('modal-review-request')"
        >
          Yorum Talebi Gönder
        </button>
      </div>
    </div>
    <div class="card-body p-10">
      <div>
        <div class="row">
          <div class="col-6 col-md-4">
            <div class="mb-8 d-flex flex-column">
              <span class="text-muted font-weight-bold mb-4">Ad</span>
              <span class="text-dark font-weight-bolder font-size-lg">{{
                name
              }}</span>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="mb-8 d-flex flex-column">
              <span class="text-muted font-weight-bold mb-4">Soyad</span>
              <span class="text-dark font-weight-bolder font-size-lg">{{
                surname
              }}</span>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="mb-8 d-flex flex-column">
              <span class="text-muted font-weight-bold mb-4">Ülke</span>
              <span
                class="text-dark font-weight-bolder font-size-lg d-inline-flex align-items-center"
              >
                <Flag
                  class="mr-2"
                  :code="countryCode"
                  size="M"
                  :hasDropShadow="true"
                  :hasBorder="true"
                  :hasBorderRadius="true"
                  gradient="real-linear"
                />
                {{ country }}
              </span>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div class="mb-8 d-flex flex-column">
              <span class="text-muted font-weight-bold mb-4">Cep Telefonu</span>
              <a
                :href="`tel: ${mobile}`"
                class="text-dark text-hover-primary font-weight-bolder font-size-lg"
                >{{ mobile }}</a
              >
            </div>
          </div>
          <div class="col-6 col-md-8">
            <div class="mb-8 d-flex flex-column">
              <span class="text-muted font-weight-bold mb-4">E-posta</span>
              <a
                :href="`mailto: ${email}`"
                class="text-dark text-hover-primary font-weight-bolder font-size-lg"
                >{{ email }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Begin: MODAL -->
    <b-modal
      id="modal-review-request"
      ref="modal-review-request"
      no-fade
      centered
      header-class="py-3"
      footer-class="py-3"
      @cancel="handleModalCancel"
      @hide="handleModalCancel"
      @ok="handleModalOk"
    >
      <template #modal-header="{ cancel }">
        <button
          type="button"
          @click="cancel"
          class="btn btn-icon btn-hover-light btn-sm"
        >
          <i class="ki ki-close icon-nm text-dark"></i>
        </button>
        <div
          class="
            d-flex
            flex-fill
            align-items-center
            justify-content-center
            pt-2
          "
        >
          <div
            class="d-flex flex-column align-items-center"
            style="margin-left: -32px"
          >
            <h5>Yorum İsteği Gönder</h5>
          </div>
        </div>
      </template>

      <div class="form-group">
        <label class="text-body font-weight-bold pl-1"
          >E-posta Hangi Dilde Gönderilsin?</label
        >
        <select
          class="form-control"
          ref="languageSelect"
          v-model="modal.language"
        >
          <option value="" disabled>Seçin</option>
          <option value="tr">Türkçe</option>
          <option value="en">İngilizce</option>
        </select>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            flex-fill
          "
        >
          <button
            type="button"
            class="btn btn-hover-transparent-dark font-weight-bolder py-4"
            @click="cancel"
          >
            İptal et
          </button>

          <button
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4"
            :disabled="!modal.language"
            @click="ok"
          >
            E-posta Gönder
          </button>
        </div>
      </template>
    </b-modal>
    <!--End: MODAL-->
    <Notification
      v-if="notification.status"
      :state="notification.state"
      :message="notification.message"
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Notification from "@/view/content/notification/Notification";

export default {
  props: {
    name: {
      type: String,
      default: "-"
    },
    surname: {
      type: String,
      default: "-"
    },
    countryCode: {
      type: String
    },
    country: {
      type: String,
      default: "-"
    },
    mobile: {
      type: String,
      default: "-"
    },
    email: {
      type: String,
      default: "-"
    },
    requestReview: {
      type: Boolean,
      default: true
    },
    reservationNumber: {
      type: String
    }
  },
  components: {
    Notification
  },
  data() {
    return {
      modal: {
        language: ""
      },
      notification: {
        status: false,
        state: "",
        message: ""
      }
    };
  },
  methods: {
    handleModalCancel() {
      this.modal.language = "";
    },
    handleModalOk() {
      const postData = {
        id: this.$route.params.id,
        lang: this.modal.language
      };

      ApiService.post("reservation/send-review-request.req.php", postData)
        .then(() => {
          this.notify(
            "success",
            "Yorum talebi müşterinin e-posta adresine gönderildi!"
          );
        })
        .catch(({ response }) => {
          this.notify("danger", response.data.errorMessage);
        });
    },
    notify(state, message) {
      this.notification.state = state;
      this.notification.message = message;
      this.notification.status = true;
      this.notification.timeout = setTimeout(() => {
        this.notification.status = false;
      }, 4000);
    }
  }
};
</script>
