<template>
  <div class="row">
    <div class="col-6 col-md-6">
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <span class="svg-icon svg-icon-2x svg-icon-success">
            <inline-svg src="media/svg/icons/Files/Import.svg" />
          </span>
          <div class="text-success font-weight-bolder font-size-h3 mt-3">
            {{ netEarnings }}
          </div>
          <span class="text-muted font-weight-bold mt-1">Net Kazanç</span>
        </div>
      </div>
    </div>
    <div class="col-6 col-md-6">
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <span class="svg-icon svg-icon-2x svg-icon-danger">
            <inline-svg src="media/svg/icons/Files/Export.svg" />
          </span>
          <div class="text-danger font-weight-bolder font-size-h3 mt-3">
            {{ iyzicoCommission }}
          </div>
          <span class="text-muted font-weight-bold mt-1">iyzico Komisyonu</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    netEarnings: {
      type: String,
      default: '-',
      required: true
    },
    iyzicoCommission: {
      type: String,
      default: '-',
      required: true
    }
  },
}
</script>

<style>
</style>