<template>
  <div class="row" v-if="!isError">
    <div class="col-md-7 col-lg-12 col-xxl-7">
      <div>
        <ReservationDetails
          ref="reservation-details"
          :reservation-number="reservation.reservationNumber"
          :departure-date="reservation.departureDateTime"
          :participants="reservation.participants"
          :status="reservation.status"
          :reservation-date="reservation.reservationDate"
          :installment="payout.installment"
          :experience="reservation.experience"
          :price="reservation.price"
          :promotion-code="reservation.promotionCode"
          :reservation-id="id"
          :is-refunded="Boolean(reservation.isRefunded)"
          :pnr="reservation.pnr"
          @refunded="get"
        />
      </div>
      <div>
        <MeetingDetails :reservation-id="id" />
      </div>
      <div>
        <Participants
          :participants="participants"
          :reservation-number="reservationNumber"
          :bookings="reservation.customerBookings"
        ></Participants>
      </div>
      <div v-if="reservation.customerBookings.length">
        <Bookings :bookings="reservation.customerBookings"></Bookings>
      </div>
    </div>
    <div class="col-md-5 col-lg-12 col-xxl-5">
      <div>
        <CustomerDetails
          :name="customer.name"
          :surname="customer.surname"
          :country-code="customer.countryCode"
          :country="customer.country"
          :mobile="customer.mobile"
          :email="customer.email"
          :request-review="reservation.status && !review"
          :reservation-number="reservationNumber"
        />
      </div>
      <div v-if="review">
        <ReviewItem :review="review" :show-reviewer="false" />
      </div>
      <div v-if="reservation.status === 1">
        <PayoutDetails
          :net-earnings="payout.merchantPayoutAmountISO"
          :iyzico-commission="payout.iyziCommissionAmountISO"
        >
        </PayoutDetails>
      </div>
      <div>
        <Files :reservation-id="id" :reservation-number="reservationNumber" />
      </div>
      <div>
        <Cancellation :reservation-id="id" @cancel="reservationCancelled" />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ReservationDetails from "@/view/pages/reservation/components/details/ReservationDetails";
import CustomerDetails from "@/view/pages/reservation/components/details/Customer";
import Files from "@/view/pages/reservation/components/details/Files";
import Cancellation from "@/view/pages/reservation/components/details/Cancellation";
import MeetingDetails from "@/view/pages/reservation/components/details/Meeting";
import PayoutDetails from "@/view/pages/reservation/components/details/Payout";
import Bookings from "@/view/pages/reservation/components/details/Bookings";
import ReviewItem from "@/view/pages/experience/components/review/ReviewItem";
import Participants from "@/view/pages/reservation/components/details/Participants";

export default {
  components: {
    ReservationDetails,
    CustomerDetails,
    Files,
    Cancellation,
    MeetingDetails,
    PayoutDetails,
    Bookings,
    ReviewItem,
    Participants
  },
  data() {
    return {
      id: this.$route.params.id,
      reservationNumber: this.$route.params.reservationNumber,
      isLoading: false,
      isError: false,
      payout: {},
      customer: {},
      reservation: {},
      participants: [],
      review: ""
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.isLoading = true;
      ApiService.get(`reservation/get.req.php?id=${this.id}`)
        .then(({ data }) => {
          this.reservation = data.data.reservation;
          this.meeting = data.data.meeting;
          this.customer = data.data.customer;
          this.payout = data.data.payout;
          this.reservation.customerBookings = data.data.otherReservations;
          this.review = data.data.review;
          this.participants = data.data.participants;
          this.isError = false;
        })
        .catch(({ response }) => {
          this.isError = true;
          alert(response.data.errorMessage);
        });
    },
    reservationCancelled() {
      this.$refs["reservation-details"].get();
    }
  }
};
</script>

<style></style>
